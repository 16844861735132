import React from 'react';
import { FaUser, FaFileContract, FaStore } from 'react-icons/fa';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="homepage-container">
      <div className="content-wrapper">
        <div className="text-section">
          <h1>MUGGLE ESCROW PLATFORM</h1>
          <p className="subtitle">Start accepting payments in less than a minute</p>
          <button className="get-started-button" onClick={() => window.location.href = '/escrow-page'}>
            Get Started
          </button>
          <div className="info-section">
            <h2>Accept BTC, ETH, POLYGON, BINANCE</h2>
            <p>in your online store</p>
            <ul>
              <li>No Middlemen - No chargebacks or custodian risk</li>
              <li>Global - Reach new markets instantly</li>
              <li>Frictionless - 1% fee and instant settlement</li>
            </ul>
          </div>
        </div>
        <div className="icon-section">
          <div className="icon-item">
            <FaUser className="icon" />
            <span>User</span>
          </div>
          <div className="icon-item">
            <FaFileContract className="icon" />
            <span>Smart Contract</span>
          </div>
          <div className="icon-item">
            <FaStore className="icon" />
            <span>Merchant</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;