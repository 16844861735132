import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './components/HomePage';
import BuyerRegistration from './components/BuyerRegistration';
import SellerRegistration from './components/SellerRegistration';
import DisputeFiling from './components/DisputeFiling';
import DisputeResolution from './components/DisputeResolution';
import EscrowPage from './components/EscrowPage';
import Navbar from './components/Navbar';
import './App.css';

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Navbar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/buyer-registration" element={<BuyerRegistration />} />
            <Route path="/seller-registration" element={<SellerRegistration />} />
            <Route path="/dispute-filing" element={<DisputeFiling />} />
            <Route path="/dispute-resolution" element={<DisputeResolution />} />
            <Route path="/escrow-page" element={<EscrowPage />} />
          </Routes>
        </main>
        <footer className="app-footer">
          <p>&copy; 2024 Muggle Escrow Platform. All rights reserved.</p>
        </footer>
      </BrowserRouter>
    </div>
  );
}

export default App;