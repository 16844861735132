import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import EscrowContractABI from '../abis/EscrowContractABI';

const DisputeFiling = () => {
  const [offerId, setOfferId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          // Request account access
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await web3.eth.getAccounts();
          setUserAddress(accounts[0]);
        } catch (error) {
          console.error("User denied account access");
        }
      }
    };
    loadWeb3();
  }, []);

  const handleFileDispute = async (e) => {
    e.preventDefault();
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(EscrowContractABI, '0x1A55227c633eFD6cF4ceA36626F35c0035a10d1A');

    try {
      await contract.methods.fileDispute(offerId, name, email).send({ from: userAddress });
      alert(`Dispute filed successfully!`);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Dispute Filing</h1>
      <form onSubmit={handleFileDispute}>
        <label>Offer ID:</label>
        <input 
          type="number" 
          value={offerId} 
          onChange={e => setOfferId(e.target.value)} 
          required 
        />
        <br />
        <label>Name:</label>
        <input 
          type="text" 
          value={name} 
          onChange={e => setName(e.target.value)} 
          required 
        />
        <br />
        <label>Email:</label>
        <input 
          type="email" 
          value={email} 
          onChange={e => setEmail(e.target.value)} 
          required 
        />
        <br />
        <button type="submit">File Dispute</button>
      </form>
    </div>
  );
};

export default DisputeFiling;