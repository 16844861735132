import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li><Link to="/">Homepage</Link></li>
        <li><Link to="/buyer-registration">Buyer Registration</Link></li>
        <li><Link to="/seller-registration">Seller Registration</Link></li>
        <li><Link to="/dispute-filing">Dispute Filing</Link></li>
        <li><Link to="/dispute-resolution">Dispute Resolution</Link></li>
        <li><Link to="/escrow-page">Escrow Page</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;