import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import EscrowContractABI from '../abis/EscrowContractABI';

const CONTRACT_ADDRESS = '0x1A55227c633eFD6cF4ceA36626F35c0035a10d1A';

const DisputeResolution = () => {
  const [offerId, setOfferId] = useState('');
  const [party, setParty] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [contract, setContract] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [disputeDetails, setDisputeDetails] = useState(null);
  const [allDisputes, setAllDisputes] = useState([]);

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          setWeb3(web3Instance);
          const contractInstance = new web3Instance.eth.Contract(EscrowContractABI, CONTRACT_ADDRESS);
          setContract(contractInstance);
          const accounts = await web3Instance.eth.getAccounts();
          setUserAddress(accounts[0]);
        } catch (error) {
          console.error("User denied account access", error);
        }
      } else {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
      }
    };
    initWeb3();
  }, []);

  useEffect(() => {
    const fetchAllDisputes = async () => {
      if (contract) {
        try {
          const disputes = await contract.methods.viewDisputes().call({ from: userAddress });
          setAllDisputes(disputes);
        } catch (error) {
          console.error("Error fetching all disputes:", error);
        }
      }
    };
    fetchAllDisputes();
  }, [contract, userAddress]);

  useEffect(() => {
    if (offerId !== '') {
      const dispute = allDisputes.find(d => d[0].toString() === offerId.toString());
      setDisputeDetails(dispute || null);
    } else {
      setDisputeDetails(null);
    }
  }, [offerId, allDisputes]);

  const handleResolveDispute = async (e) => {
    e.preventDefault();
    if (!contract || !userAddress) return;

    try {
      await contract.methods.resolveDispute(offerId, party).send({ from: userAddress });
      alert(`Dispute for offer ${offerId} resolved successfully!`);
      setOfferId('');
      setParty('');
      // Refresh the disputes list
      const disputes = await contract.methods.viewDisputes().call({ from: userAddress });
      setAllDisputes(disputes);
    } catch (error) {
      console.error("Error resolving dispute:", error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Dispute Resolution</h1>
      <form onSubmit={handleResolveDispute}>
        <label>Offer ID:</label>
        <input
          type="number"
          value={offerId}
          onChange={e => setOfferId(e.target.value)}
          required
        />
        <br />
        <label>Resolving in favor of (address):</label>
        <input
          type="text"
          value={party}
          onChange={e => setParty(e.target.value)}
          required
        />
        <br />
        <button type="submit">Resolve Dispute</button>
      </form>
      {disputeDetails && (
        <div>
          <h2>Dispute Details</h2>
          <p>Offer ID: {disputeDetails[0]}</p>
          <p>Filed by: {disputeDetails[1]}</p>
          <p>Name: {disputeDetails[2]}</p>
          <p>Email: {disputeDetails[3]}</p>
        </div>
      )}
      <h2>All Disputes</h2>
      <ul>
        {allDisputes.map((dispute, index) => (
          <li key={index}>
            Offer ID: {dispute[0].toString()}, Filed by: {dispute[1]}, Name: {dispute[2]}, Email: {dispute[3]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DisputeResolution;