import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import EscrowContractABI from '../abis/EscrowContractABI';
import './EscrowPage.css';

const CONTRACT_ADDRESS = '0x1A55227c633eFD6cF4ceA36626F35c0035a10d1A';

const EscrowPage = () => {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [userAddress, setUserAddress] = useState('');
  const [offerPrice, setOfferPrice] = useState('');
  const [offerDescription, setOfferDescription] = useState('');
  const [offerId, setOfferId] = useState('');
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [cancelOfferId, setCancelOfferId] = useState('');

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          setWeb3(web3Instance);
          const contractInstance = new web3Instance.eth.Contract(EscrowContractABI, CONTRACT_ADDRESS);
          setContract(contractInstance);
          const accounts = await web3Instance.eth.getAccounts();
          setUserAddress(accounts[0]);
        } catch (error) {
          console.error("User denied account access", error);
        }
      } else {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
      }
    };
    initWeb3();
  }, []);

  const handleCreateOffer = async (e) => {
    e.preventDefault();
    if (!contract || !userAddress) return;

    try {
      const priceInWei = web3.utils.toWei(offerPrice, 'ether');
      await contract.methods.createOffer(priceInWei, offerDescription).send({ from: userAddress });
      alert('Offer created successfully!');
      setOfferPrice('');
      setOfferDescription('');
    } catch (error) {
      console.error("Error creating offer:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleAcceptOffer = async (e) => {
    e.preventDefault();
    if (!contract || !userAddress) return;

    try {
      const offer = await contract.methods.offers(offerId).call();
      await contract.methods.acceptOffer(offerId).send({ 
        from: userAddress, 
        value: offer.price 
      });
      alert(`Offer ${offerId} accepted successfully!`);
      setOfferId('');
    } catch (error) {
      console.error("Error accepting offer:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleReleasePayment = async (e) => {
    e.preventDefault();
    if (!contract || !userAddress) return;

    try {
      await contract.methods.releasePayment(offerId).send({ from: userAddress });
      alert(`Payment for offer ${offerId} released successfully!`);
      setOfferId('');
    } catch (error) {
      console.error("Error releasing payment:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleWithdrawal = async (e) => {
    e.preventDefault();
    if (!contract || !userAddress) return;

    try {
      const amountInWei = web3.utils.toWei(withdrawalAmount, 'ether');
      await contract.methods.withdraw(amountInWei).send({ from: userAddress });
      alert(`Withdrawal of ${withdrawalAmount} ETH successful!`);
      setWithdrawalAmount('');
    } catch (error) {
      console.error("Error during withdrawal:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleCancelOffer = async (e) => {
    e.preventDefault();
    if (!contract || !userAddress) return;

    try {
      await contract.methods.cancelOffer(cancelOfferId).send({ from: userAddress });
      alert(`Offer ${cancelOfferId} cancelled successfully!`);
      setCancelOfferId('');
    } catch (error) {
      console.error("Error cancelling offer:", error);
      alert(`Error: ${error.message}`);
    }
  };

  if (!web3) {
    return <div className="loading">Loading Web3, accounts, and contract...</div>;
  }

  return (
    <div className="escrow-page">
      <h1>Escrow Platform</h1>
      <div className="escrow-forms">
        <form onSubmit={handleCreateOffer} className="escrow-form">
          <h2>Create Offer (Seller)</h2>
          <div className="form-group">
            <label>Price (ETH):</label>
            <input 
              type="text" 
              value={offerPrice} 
              onChange={e => setOfferPrice(e.target.value)} 
              required
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <input 
              type="text" 
              value={offerDescription} 
              onChange={e => setOfferDescription(e.target.value)} 
              required
            />
          </div>
          <button type="submit">Create Offer</button>
        </form>

        <form onSubmit={handleAcceptOffer} className="escrow-form">
          <h2>Accept Offer (Buyer)</h2>
          <div className="form-group">
            <label>Offer ID:</label>
            <input 
              type="number" 
              value={offerId} 
              onChange={e => setOfferId(e.target.value)} 
              required
            />
          </div>
          <button type="submit">Accept Offer</button>
        </form>

        <form onSubmit={handleReleasePayment} className="escrow-form">
          <h2>Release Payment (Buyer)</h2>
          <div className="form-group">
            <label>Offer ID:</label>
            <input 
              type="number" 
              value={offerId} 
              onChange={e => setOfferId(e.target.value)} 
              required
            />
          </div>
          <button type="submit">Release Payment</button>
        </form>

        <form onSubmit={handleWithdrawal} className="escrow-form">
          <h2>Withdrawal (Seller)</h2>
          <div className="form-group">
            <label>Withdrawal Amount (ETH):</label>
            <input 
              type="text" 
              value={withdrawalAmount} 
              onChange={e => setWithdrawalAmount(e.target.value)} 
              required
            />
          </div>
          <button type="submit">Withdraw</button>
        </form>

        <form onSubmit={handleCancelOffer} className="escrow-form">
          <h2>Cancel Offer (Seller)</h2>
          <div className="form-group">
            <label>Offer ID:</label>
            <input 
              type="number" 
              value={cancelOfferId} 
              onChange={e => setCancelOfferId(e.target.value)} 
              required
            />
          </div>
          <button type="submit">Cancel Offer</button>
        </form>
      </div>
    </div>
  );
};

export default EscrowPage;