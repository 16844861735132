import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import EscrowContractABI from '../abis/EscrowContractABI';
import './BuyerRegistration.css';

const BuyerRegistration = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        try {
          // Request account access
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);

          const contractInstance = new web3Instance.eth.Contract(
            EscrowContractABI,
            '0x1A55227c633eFD6cF4ceA36626F35c0035a10d1A'
          );
          setContract(contractInstance);

          const accounts = await web3Instance.eth.getAccounts();
          setUserAddress(accounts[0]);
        } catch (error) {
          console.error("Error initializing Web3", error);
          alert("Failed to connect to Ethereum network. Please make sure you have MetaMask installed and connected.");
        }
      } else {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        alert("Please install MetaMask to use this dApp!");
      }
    };

    initWeb3();
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!web3 || !contract || !userAddress) {
      alert("Please connect to MetaMask first!");
      return;
    }

    try {
      await contract.methods.registerBuyer(name, email).send({ from: userAddress });
      alert(`Buyer registration successful!`);
      setName('');
      setEmail('');
    } catch (error) {
      console.error("Registration error:", error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Buyer Registration</h1>
      <form onSubmit={handleRegister}>
        <label>Name:</label>
        <input type="text" value={name} onChange={e => setName(e.target.value)} required />
        <br />
        <label>Email:</label>
        <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
        <br />
        <button type="submit">Register</button>
      </form>
      {userAddress && <p>Connected Address: {userAddress}</p>}
    </div>
  );
};

export default BuyerRegistration;